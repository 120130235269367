<template>
  <InputWithAction
    :icon="'search'"
    :showActionButton="true"
    :isLoading="loadingNewBrand"
    :placeHolderText="$t('bvs.placeholder')"
    @do-something="searchBrand($event)" />
</template>

<script>
import { socialMediaService } from '@/_services'

export default {
  name: 'InputSearchBrand',
  components: {
    InputWithAction: () => import('./InputWithAction.vue')
  },
  data () {
    return {
      countResults: 0,
      loadingNewBrand: false,
      newSearchedBrands: []
    }
  },
  mounted () {
    this.$root.$on('remove-brand-from-search', (brand) => {
      this.newSearchedBrands = this.newSearchedBrands.filter(e => e.screen_name !== brand.screen_name)
      this.$emit('new-brands', { newBrands: this.newSearchedBrands })
    })
  },
  computed: {
    currentTracking () {
      return this.accountTrackItems.brandsTracking
    },
    accountTrackItems () {
      return this.$store.getters['account/getAccount']
    }
  },
  methods: {
    async searchBrand (brandSearch) {
      const textFormated = brandSearch.replaceAll('@', '').replaceAll(' ', '').toLowerCase().trim()
      if (textFormated.length > 0) {
        let findBrandInAccount
        let findBrandInSuggestionList
        this.currentTracking.forEach(e => {
          if (e[e.type].screen_name && (e[e.type].screen_name.toLowerCase() === textFormated)) { findBrandInAccount = e }
          if (e[e.type].name && (e[e.type].name.toLowerCase() === textFormated)) { findBrandInAccount = e }
        })

        this.newSearchedBrands.forEach(e => {
          if (e.screen_name.toLowerCase() === textFormated) { findBrandInSuggestionList = e }
        })

        if (!findBrandInAccount && !findBrandInSuggestionList) {
          this.loadingNewBrand = true
          const res = await socialMediaService.searchUserOnTwitter(textFormated)
          if (res.length) {
            this.newSearchedBrands.unshift(res[0])
            this.$emit('new-brands', { newBrands: this.newSearchedBrands })
          } else {
            this.$store.commit('alerts/alert', {
              message: this.$t('bvs.not_found'),
              position: 'bottomRight',
              showAlert: true
            })
          }
          this.loadingNewBrand = false
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$t('bvs.user_exist'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
